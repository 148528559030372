import React from "react"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolItemSportsSection from "../../sections/schools/SchoolItemSportsSection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"
import Layout from "../../components/Layout"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"

const SchoolPageSports = props => {
  return (
    <Layout
      wrapperClass={"school-item subjects-item"}
      pageTitle={`${props.pageContext.currentSchool.name} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        isMainSchoolTab={false}
        state6="active"
        school={props.pageContext.currentSchool}
        currentSchoolScholarships={props.pageContext.currentSchoolScholarships}
        schoolSectionHeader="shrinked"
        schoolSectionHeaderLogo="hide"
      />
      <SchoolItemSportsSection
        certs={props.pageContext.certs}
        sports={props.pageContext.sports}
        sportPaymentFormats={props.pageContext.sportPaymentFormats}
        isSchoolPage={true}
      />
      <SchoolFooterSection
        school={props.pageContext.currentSchool}
        schools={props.pageContext.schools}
      />
      <SideNavApplyDocsSection school={props.pageContext.currentSchool} />
    </Layout>
  )
}

export default SchoolPageSports
